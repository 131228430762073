import CheckSVG from '../../../CheckBOLD.svg'
export default function AddOnsNoPrice() {
    return (
        <section id="detailed-pricing" className="w-full bg-dl">
                
                <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                    <h2 class="text-5xl w-full text-center py-5 font-bold bg-clip-text text-transparent bg-pg">Extra Add-ons</h2>
                    <p class="mb-5 font-light sm:text-xl text-ll">Looking for additional support not included in your stream? Check out Inventum's complementary services below which are designed to, for example, provide more help with the chosen subjects, additional testing, guidance with your university application or finding an examination center.
                    </p>
                </div>
                <div class="bg-dl max-w-2xl mx-auto">
                    <div class=" w-full text-center grid grid-cols-1 p-4 text-sm border-t border-b md:gap-x-16 justify-between bg-gray-800 border-gray-700 text-ll font-bold sm:text-[1rem]">
                        <div class="flex items-center justify-center">Extra add-ons</div>
                    </div>
                    <div class="grid grid-cols-1 px-4 py-5 text-sm text-ll border-b  md:gap-x-16 justify-between border-gray-600 ">
                        <div class="text-ll w-full font-bold sm:text-[1rem] text-xs">Additional Tutor Session (1 session)</div>
                    </div>
                    <div class="grid grid-cols-1 px-4 py-5 text-sm text-ll border-b  md:gap-x-16 justify-between border-gray-600 ">
                        <div class="text-ll w-full font-bold sm:text-[1rem] text-xs">Additional Tutor Sessions (10 sessions)</div>
                    </div>
                    <div class="grid grid-cols-1 px-4 py-5 text-sm text-ll border-b  md:gap-x-16 justify-between border-gray-600 ">
                        <div class="text-ll w-full font-bold sm:text-[1rem] text-xs">Additional Tutor Sessions (20 sessions)</div>
                    </div>
                    <div class="grid grid-cols-1 px-4 py-5 text-sm text-ll border-b  md:gap-x-16 justify-between border-gray-600 ">
                        <div class="text-ll w-full font-bold sm:text-[1rem] text-xs">Additional Tutor Sessions (30 sessions)</div>
                    </div>
                    <div class="grid grid-cols-1 px-4 py-5 text-sm text-ll border-b  md:gap-x-16 justify-between border-gray-600">
                        <div class="text-ll w-full font-bold sm:text-[1rem] text-xs">Help with university applications</div>
                    </div>
                    <div class="grid grid-cols-1 px-4 py-5 text-sm text-ll border-b  md:gap-x-16 justify-between border-gray-600">
                        <div class="text-ll w-full font-bold sm:text-[1rem] text-xs">Personalised learning experience with GL Progression Test Data and CAT 4</div>
                    </div>
                    <div class="grid grid-cols-1 px-4 py-5 text-sm text-ll border-b  md:gap-x-16 justify-between border-gray-600">
                        <div class="text-ll w-full font-bold sm:text-[1rem] text-xs">Help with university selection and tertiary study path</div>   
                    </div>
                    <div class="grid grid-cols-1 px-4 py-5 text-sm text-ll border-b  md:gap-x-16 justify-between border-gray-600">
                        <div class="text-ll w-full font-bold sm:text-[1rem] text-xs">Assistance with examination arrangements</div>   
                    </div>
                </div>
                
        
            </section>
    )
}